import { isValidElement, ReactChild } from 'react';
import dynamic from 'next/dynamic';
import { HStack, Icon, Stack, StackProps, Text } from '@chakra-ui/react';
import {
  ArrowSquareOut,
  Icon as PhosphorIcon,
  Envelope,
  MapPin,
  Phone,
  Buildings,
  Briefcase,
} from '@phosphor-icons/react';
import { TFunction } from 'next-i18next';

const Link = dynamic(() => import('@/components/frontend/Link'));

export interface ContactInfoItem {
  icon: PhosphorIcon;
  content: ReactChild;
  link?: string;
}
export type ContactInfoStackProps = {
  items: ContactInfoItem[];
  displayLinks?: boolean;
};

export const getContactInfoStackItems = (
  {
    address,
    areas,
    email,
    phone,
    company,
    bioLink,
    businessId,
  }: {
    address?: string | null;
    areas?: string | null;
    email?: string | null;
    phone?: string | null;
    company?: string | null;
    bioLink?: string | null;
    businessId?: string | null;
  },
  t?: TFunction,
): ContactInfoItem[] => {
  if (bioLink && !t) {
    throw new Error('bioLink requires translation function');
  }
  return [
    address && {
      icon: MapPin,
      content: address,
      link: `https://www.google.fi/maps/search/?api=1&query=${new URLSearchParams(
        address,
      ).toString()}`,
    },
    areas && {
      icon: MapPin,
      content: areas,
    },
    phone && {
      icon: Phone,
      link: `tel:${phone.replace(/\s/g, '')}`,
      content: phone,
    },
    email && { icon: Envelope, link: `mailto:${email}`, content: email },
    company && { icon: Buildings, content: company },
    businessId && { icon: Briefcase, content: businessId },
    bioLink && {
      icon: ArrowSquareOut,
      link: bioLink,
      content: t ? t('read_user_bio', 'Välittäjän blog / insta') : 'noop',
    },
  ].filter(Boolean) as ContactInfoItem[];
};

const ContactInfoStack = ({
  items,
  displayLinks = false,
  ...rest
}: ContactInfoStackProps & StackProps) => {
  return (
    <Stack spacing={2} wordBreak="break-word" {...rest}>
      {items.map(({ icon, content, link }, i) => (
        <HStack
          // eslint-disable-next-line react/no-array-index-key
          key={`contact-icon-${i}`}
          {...(displayLinks && link ? { as: Link, href: link } : null)}
        >
          <Icon color="success.500" as={icon} boxSize="24px" />
          {isValidElement(content) ? (
            content
          ) : (
            <Text fontSize="sm">{content}</Text>
          )}
        </HStack>
      ))}
    </Stack>
  );
};

export default ContactInfoStack;
