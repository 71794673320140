import { Avatar, AvatarProps } from '@chakra-ui/react';
import { Maybe } from '@/generated/graphql';
import { optimizedPublicContainerAttachmentUrl } from '@/lib/urls';

export type UserAvatarProps = {
  user?: Maybe<{
    name: Maybe<string>;
    avatar_attachment?: Maybe<{
      container: string;
      file_path: string;
    }>;
  }>;
} & AvatarProps;

const UserAvatar = ({ user, ...avatarProps }: UserAvatarProps) => {
  return (
    <Avatar
      size="2xl"
      name={user?.name || ''}
      src={optimizedPublicContainerAttachmentUrl(
        user?.avatar_attachment,
        256,
        70,
      )}
      colorScheme="green"
      sx={{
        img: {
          objectPosition: '50% 0',
        },
      }}
      {...avatarProps}
    />
  );
};

export default UserAvatar;
