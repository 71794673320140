import { FC, forwardRef } from 'react';
import { GridProps, Grid } from '@chakra-ui/react';
import { useLayout } from '@/lib/hooks/useApp';

export type Overlap = 'default' | 'expanded';
type OverlapValue = (string | null)[];

interface Props {
  overlap?: Overlap;
  noOverlap?: boolean;
  noPrint?: boolean;
}

/* minmax for hero not being too low cause content is absolute in desktop and height gets set based on aspect ratio
 * NOTE: There's a bug, or a feature, where minmax with percentage will cause layout to break in mobile so dont use percentages
 */
const getRowTempl = (overlap: string) => `
  [hero] minmax(calc(${overlap} + 50px), 1fr)
  [overlap] ${overlap}
  [page] min-content
`;

const calcGridTemplateRows = (overlap: OverlapValue) => {
  if (!overlap) {
    return undefined;
  }
  if (typeof overlap === 'string') {
    return getRowTempl(overlap);
  }
  return overlap.map((ol) => ol && getRowTempl(ol));
};

export const layoutMaxWidth = [
  'min(calc(100% - 2 * var(--chakra-space-5)), calc(var(--chakra-sizes-container-2xl) - 2rem))',
  null,
  'min(calc(100% - 2 * var(--chakra-space-5)), calc(var(--chakra-sizes-container-2xl) - 100px))',
];

// Adds gutters and sets max-width for the content
const layoutGridColumns = [
  `1fr ${layoutMaxWidth[0] as string} 1fr`,
  null,
  `1fr ${layoutMaxWidth[2] as string} 1fr`,
];

export const rowGap: number[] = [8, 10, 14];

const defaultOverlapValue = ['80px', null, '100px'];
const overlaps: { [K in Overlap]: OverlapValue } = {
  default: defaultOverlapValue,
  expanded: ['250px', null, '315px'],
};

const resolveOverlap = (type?: Overlap) =>
  !type ? overlaps.default : overlaps[type];

const printStyles = {
  display: 'block', // dont use grid as it messes up possible page-break-before/after/inside
  '> section': { display: 'none' }, // Hide sections from prints because semantically they dont belong in the article, printing should apply to the content, not everything
};
/*
 * About zIndexes
 * The site background is light grey. This is the fallback for everything. The Content however must mostly be on white, but the white also goes on top of the hero, but
 * only partially. So there's some layering going on.
 */
const ArticleLayout: FC<GridProps & Props> = ({
  overlap = 'default',
  noOverlap,
  noPrint,
  ...props
}) => {
  const { overlap: controlledOverlap } = useLayout();
  const currentOverlap = resolveOverlap(controlledOverlap || overlap);

  return (
    <Grid
      as="article"
      gridTemplateColumns={layoutGridColumns}
      gridTemplateRows={
        noOverlap ? 'auto' : calcGridTemplateRows(currentOverlap)
      }
      alignItems="stretch"
      transition="grid-template-rows .25s ease-in"
      sx={{
        '@media print': noPrint ? { display: 'none !important' } : printStyles,
        '> *': {
          gridColumn: 2,
          width: '100%',
        },
        '> [data-fields-layout]': {
          gridColumn: '1/-1',
        },
        ...(noOverlap
          ? // NOTE: should only apply to .hero:first-child, but in practice it makes no different. :first-child throws @emotion error.
            {
              '> .hero': {
                maxHeight: '600px',
              },
            }
          : {
              '> .hero': {
                gridRow: 'hero / page',
                maxHeight: [
                  null,
                  null,
                  Array.isArray(currentOverlap) && currentOverlap[2]
                    ? `calc(600px + ${
                        Number.parseInt(currentOverlap[2], 10) / 2
                      }px)`
                    : '600px',
                ],
              },
              '> .hero .hero-content': {
                mb: currentOverlap,
              },
              '> *:not(.hero)': {
                gridRow: 'overlap / -1',
              },
            }),
      }}
      {...props}
    />
  );
};

const fieldsSx = {
  '@media print': {
    ...printStyles,
    '> *:not(section):not(:last-child)': { mb: 10 },
  },
  '> *': {
    gridColumn: 2,
    width: '100%',
    zIndex: 1,
  },
  '> [data-no-gap]:not(:last-child)': {
    // use to negate the gap set by rowGap
    mb: rowGap.map((g) => -g),
  },
};
/*
 * Layout that wraps sections (alternate would be wrapping text content etc.)
 * needs to have spacing above and background set as grey. However not when it's a standalone, because
 * if it's the only content, it will go over hero and ruin the overlapping.
 */

export const FieldsLayout = forwardRef<
  HTMLDivElement,
  GridProps & { sectionsLayout?: boolean }
>(({ sectionsLayout, sx, ...props }, ref) => (
  <Grid
    ref={ref}
    gridRowGap={rowGap}
    gridTemplateColumns={layoutGridColumns}
    data-fields-layout
    _empty={{ p: 0, visibility: 'hidden' }}
    {...(sectionsLayout
      ? {
          mb: '0 !important',
          layerStyle: 'fullWidth',
          pb: rowGap,
          sx: {
            ...fieldsSx,
            '[data-fields-layout] > &': {
              bg: 'backgroundColor',
              pt: rowGap,
            },
            ...sx,
          },
        }
      : { sx: { ...fieldsSx, ...sx } })}
    {...props}
  />
));

export default ArticleLayout;
