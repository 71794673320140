import { Box, ChakraProps, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { ReactNode } from 'react';
import { Author as AuthorProps, Blogger } from '@/types';
import { getContactInfoStackItems } from '@/components/common/ContactInfoStack';
import UserAvatar from '../common/UserAvatar';

const Image = dynamic(() => import('./Image'));
const Teaser = dynamic(() => import('./Teaser'));
const ContactInfoStack = dynamic(
  () => import('@/components/common/ContactInfoStack'),
);

type Props = AuthorProps | Blogger;

const Author = (props: Props) => {
  const { t } = useTranslation('frontoffice');
  const {
    first_name: firstName,
    last_name: lastName,
    name,
    description,
    ...restBlogger
  } = props;
  if (!name && !lastName) {
    return null;
  }
  const { profile_url, email, phone, areas, roles, image } =
    restBlogger as Partial<Blogger>;

  const fullName =
    name || `${firstName ? `${firstName} ` : ''}${lastName as string}`;

  return (
    <Teaser
      heading={{ title: fullName, variant: 'subtitle' }}
      meta={
        image?.src ? (
          <Image
            src={image.src}
            alt={image.alt || fullName}
            width="98"
            height="98"
            containerProps={{ mb: 2 }}
            circle
          />
        ) : (
          <UserAvatar user={{ name: fullName }} size="xl" mb="2" />
        )
      }
      link={
        profile_url
          ? {
              url: profile_url,
              title: t('get_to_know', 'Tutustu'),
            }
          : null
      }
      innerProps={{ maxW: '360px' }}
    >
      {roles ? <Text fontSize="md">{roles}</Text> : null}
      {description && <Text fontSize="md">{description}</Text>}
      <ContactInfoStack
        items={getContactInfoStackItems({
          email,
          phone,
          areas,
        })}
      />
    </Teaser>
  );
};

export const TeaserAuthor = ({
  author: { first_name: firstName, last_name: lastName, name, ...author },
  children,
  ...props
}: { children?: ReactNode; author: Props } & ChakraProps) => {
  if (!name && !lastName) {
    return null;
  }

  const { image, profile_url } = author as Partial<Blogger>;
  const isRealtor = !!profile_url;

  const fullName =
    name || `${firstName ? `${firstName} ` : ''}${lastName as string}`;

  const content = (
    <>
      <Text textTransform="uppercase">{fullName}</Text>
      {children && <Text color="secondaryColor">{children}</Text>}
    </>
  );

  // Show avatar for realtors only if they dont have image.
  // For consistency with chat etc. Visiting bloggers dont
  // necessarily need avatar especially in this small format
  return image?.src || isRealtor ? (
    <Flex alignItems="center" {...props}>
      {image?.src ? (
        <Image
          src={image.src}
          alt={image.alt || fullName}
          width="48"
          height="48"
          containerProps={{ flexShrink: 0 }}
          circle
        />
      ) : (
        <UserAvatar user={{ name: fullName }} size="md" />
      )}
      <Box ml={[2, null, 3]} letterSpacing=".5px" fontSize="xs">
        {content}
      </Box>
    </Flex>
  ) : (
    <Box {...props} letterSpacing=".5px" fontSize="xs">
      {content}
    </Box>
  );
};

export default Author;
